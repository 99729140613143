export const tableColumn = [
	{
		label: '系统来源',
		prop: 'channelName',
		width: '250px',
	},
	{
		label: '-1星',
		prop: 'minusOneStar',
	},
	{
		label: '0星',
		prop: 'zeroStar',
	},
	{
		label: '1星',
		prop: 'oneStar',
	},
	{
		label: '2星',
		prop: 'twoStar',
	},
	{
		label: '3星',
		prop: 'threeStar',
	},
	{
		label: '4星',
		prop: 'fourStar',
	},
	{
		label: '4+星',
		prop: 'fpfFStar',
	},
	{
		label: '5星',
		prop: 'fiveStar',
	},
	{
		label: '总计',
		prop: 'total',
	},
]

export const filters = [
	[
		{
			label: '来源渠道',
			type: 'select',
			attr: 'source',
			placeholder: '请选择',
			column: 6,
			option: [{ label: '系统分配', val: 1 }],
			labelWidth: '74px',
		},
	],
	[
		{
			label: '时间范围',
			type: 'radioGroup',
			attr: 'interval',
			column: 12,
			labelWidth: '74px',
			option: [
				{ label: '本日', val: 0 },
				{ label: '昨日', val: 1 },
				{ label: '本周', val: 2 },
				{ label: '上周', val: 3 },
				{ label: '本月', val: 4 },
				{ label: '上月', val: 5 },
			],
		},
		{
			label: '',
			type: 'dateRange',
			attr: 'date',
			column: 6,
			labelWidth: '100px',
            valueFormat: "YYYY-MM-DD"
		},
		{
			type: 'button',
			column: 6,
		},
	],
]
