<template>
  <div class="xinsheng-warp system-warp">
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @submit="handleSubmit"
      @reset="handleReset"
    />

    <div class="container">
      <el-tabs
        class="tabs-block"
        v-model="activeName"
      >
        <el-tab-pane
          label="来源渠道"
          name="1"
        >
          <CustomerTable
            v-if="tableData"
            :tableColumn="tableColumn"
            :tableData="tableData"
          />
          <!-- <CustomerPagination
            :total="total"
            @currentChange="currentChange"
          /> -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref } from "vue";
import { tableColumn, filters } from "./index.js";
import CustomerTable from "@/components/table";
// import CustomerPagination from "@/components/pagination";
import { useStore } from "vuex";
import { ElTabPane, ElTabs } from "element-plus";
import { reset } from "@/until";
import { getChannelList } from "@/api/method/channel";

export default {
  name: "ClientVisit",
  components: {
    FilterForm,
    CustomerTable,
    // CustomerPagination,
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      source: null,
      interval: null,
      date: null,
    });
    const pagination = reactive({
      page: 1,
      size: 10,
    });

    const tableData = computed(() => state.customerChannelList);
    // const total = computed(
    //   () => state.customerChannelList && state.customerChannelList.total
    // );

    const getList = () => {
      const { date, ...param } = filterData;
      const startTime = date && date[0];
      const endTime = date && date[1];

      dispatch("customerChannelList", {
        ...param,
        startTime,
        endTime,
      });
    };

    onMounted(() => {
      getList();
      channelList();
    });

    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const activeName = ref("1");

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    const channelList = async () => {
      const res = await getChannelList();
      filters[0][0].option = res.data.map((item) => ({
        label: item.name,
        val: item.id,
      }));
    };

    return {
      filters,
      filterData,
      tableColumn,
      tableData,

      currentChange,
      handleSubmit,
      activeName,
      handleReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-btn {
  padding-bottom: 40px;
}
.container {
  width: 100%;
  .tabs-block {
    .el-tabs__item.is-active {
      color: #406fff;
    }
    .el-tabs__active-bar {
      background: #406fff;
    }
    .el-tabs__nav-wrap::after {
      border: none;
    }
  }
}
</style>
